<template>
  <div class="content_formService">
    <Menu2></Menu2>
    <Menu></Menu>
    <div class="box_service">
     <div class="col-1"></div>
     <div class="col-2">
        <div class="container_form">
          <div class="box-tittle">
            <div class="tittle">
                <h5>Contratar servicios de<br> {{this.service}}</h5>
                <p>Ingresa tus datos y en breve nos comunicaremos contigo.</p>
            </div>
            <div class="logo portrait">
                <img src="../Imagenes/logo-gris.png" alt="Logo Altera">
            </div>
          </div>
          <div class="box-form">
            <div class="box-form-1">
              <div>
                <p>Nombre</p>
                <input type="text" v-model="name" name="name" placeholder="Ingresa tu nombre">
              </div>
              <div>
                <p>Mail</p>
                <input type="text" v-model="email" name="email" placeholder="Ingresa tu mail">
              </div>
              <div>
                <p>Teléfono</p>
                <input type="text" v-model="phone"  name="phone" placeholder="Ingresa tu número">
              </div>
            </div>
            <div class="box-form-2">
              <p>Mensaje</p>
              <textarea name="" v-model="message"  cols="30" rows="10" placeholder="Dejanos tus comentarios"></textarea>
            </div>
          </div>
          <div class="btn-contact-box">
            <input class="btn-contact" @click="checkForm" type="submit" value="Enviar">
          </div>
          <div class="error">
            <p v-if="errors.length">
              <ul>
                  <li v-for="(error,index) in errors" :key="index">{{ error }}</li>
              </ul>
            </p>
          </div>
        </div>
     </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Menu from '@/components/Menu.vue';
import Menu2 from '@/components/Menu2.vue';
import Footer from '@/components/Footer.vue';
import axios from 'axios'

export default {
  name: 'form_service',
  components: {
    Menu,
    Menu2,
    Footer,
  },
   data() {
    return {
      name: '',
      email: '',
      subject: '',
      message: '',
      phone:'',
      service:'',
      errors: [],
      regxEmail: /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      regxPhone:/[^0-9\\+]/g
    }
   },
  methods: {   
    sendInformation(){
      let data = {
        "to": "contacto@alteraacademy.com", 
        "from": this.email,
        "sender":this.name,
        "subject": this.service,
        "phone": this.phone,
        "msg": 'Servicio: ' + this.service + '<br>' +'Nombre: ' + this.name + ' <br> Correo: ' + this.email + ' <br> Teléfono: ' + this.phone + ' <br> Mensaje: ' + this.message,
      }
      axios
        .post('https://sender.alteracloud.com/send',data)
          .then((response)=>{
            console.log(response.data);
            this.email="";
            this.name="";
            this.message="";
            this.phone="";
            this.$router.push({ name: "confirmacion"})
          })
          .catch((error)=>{
          // Si esto ocurre mostrar un mensaje de "Fallo en el servidor", el cual deberia desaparecer unos segundos despues
            console.log(error)
          })
      },
    checkForm() {
      // Falta bloquear el boton cuando se está procesando y desbloquearlo cuando termine.
      this.errors = [];
      if ((!this.name)||(!this.email)||(!this.message)||(!this.phone))
      {
        this.errors.push('Uno o más campos presentan un error');
      } 
      if (this.name.length <4 || this.email.length <5 || this.message.length <4 || this.phone.length <9 ) {
        this.errors.push('Sus datos deben tener como  mínimo 5 caracteres');
      }
      if (!this.errors.length) {
        this.sendInformation();
      } else {
          return false;
        }
      },
      getParams(param) {
        let queryString = window.location.search
        let params = new URLSearchParams(queryString)
        return params.get(param)
      },    
    },
  mounted() {
    let service = this.getParams('serviceSelect')
    if(service) {
      this.service = service 
      return
    }
  }
};
</script>
<style scoped>
.prueba{
  height: 70vh;
  padding-top: 8rem;
}
.content_formService{
  font-family: "Poppins";
}
.box_service{
  background-image: url(../Imagenes/servicios/contratarServicios.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top:8em;
}
.container_form{
  background-color: #fff;
  padding: 2rem 2rem 1rem 2rem;
  border-radius: 12px;
  max-width: 500px;
}
.col-1{
    width: 50%;
}
.col-2{
    width: 50%;
}
.box-tittle{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.tittle h5{
  color: #6154b4;
  font-weight: 600;
}
.tittle p{
 font-size: 10px;
}
.logo img{
  width: 2rem;
}
.box-form{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
 .box-form p{
    font-size: 12px;
    margin: 0;
  }
  .box-form input{
    background-color: #dddd;
    border: 1px solid #dddd;
    border-radius: 20px;
    padding: 0.2rem 1rem;
    margin-bottom: 1rem;
    width: 200px;

  }
  .box-form textarea::placeholder,
  .box-form input::placeholder{
    font-size: 12px;
  }
  .box-form textarea{
    background-color: #dddd;
    border: 1px solid #dddd;
    border-radius: 20px;
    padding: 0.5rem 1rem;
    height: 200px;
    width: 200px;
  }
  .btn-contact-box{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .btn-contact{
    border: 1.5px solid #4ff5dd;
    background-color: #4ff5dd;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0.5rem;
    width: 110px;
    margin-top: 3rem;
    cursor: pointer;
  }

  input.btn-contact {
    color: #525bb7!important;
    font-weight: 600;
    font-size: 16px;
    text-decoration: none;
  }
  .error{
   margin-top: 2rem;
   width: 100%;
   background-color: #f2f2f2;
   font-size: 10px;
  }
  .error ul li{
    list-style: none;
  }
  textarea:active,
  textarea:focus,
  input:active,
  input:focus {
      outline: 0;
      box-shadow: none
  }

@media (max-width: 991px){
    .portrait{
      display: none!important;
    }
    .col-1{
      display: none!important;
    }
    .col-2{
      width: 100%;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
}
@media (min-width: 992px){
  .mobile{
    display: none!important;
  }
  .box-tittle{
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
  }
  .container_form{
    margin: 2rem 3rem 6rem 3rem;
    width: fit-content;
  }
  .box-form{
    flex-direction: row;
    text-align: left;
  }
  .box-form-2{
    margin-left: 2rem;
  }
  .box-form-1{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    height: 14rem;
  }
}

</style>